@keyframes fade-in {
	0% {
		filter: blur(10px);
		opacity: 0;
	}
	100% {
		filter: blur(0px);
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fixed {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.3);
}

.intro {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.cover {
	background-color: rgba(0, 0, 0, 0.95);
}

.hidden {
	opacity: 0;
}

.slide {
	background: transparent;
	padding: 18px 0px;
	border-top: 2px solid #454545;
	border-bottom: 2px solid #454545;
	cursor: pointer;
	position: relative;
}
.slide svg {
	height: 28px;
	position: absolute;
	top: -16px;
	left: calc(50% - 18px);
	transform: translateX(-50%, 50%);
}
.slide--active {
	background: linear-gradient(180deg, rgba(19, 19, 19, 0) 50%, rgba(243, 101, 101, 0.21) 100%);
	border-top: 2px solid #f36565;
	border-bottom: 2px solid #f36565;
}
.slide__date {
	font-weight: bold;
	font-size: 14px;
	line-height: 15px;
	text-align: center;
	letter-spacing: 0.1em;
	color: rgba(88, 88, 88, 0.7);
}
.slide__date--active {
	color: #ffffff;
}
.slide__marker {
	position: absolute;
	bottom: 0px;
	left: calc(50% - 8px);
	transform: translateX(-50%, 50%);
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #f36565;
}

.form-wrapper {
	width: 100%;
	background-color: white;
}
.field {
	border: none;
	position: relative;
	margin: 0 0 16px;
	padding: 0;
}
.field-group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.field-group .field {
	width: calc(50% - 8px) !important;
}
.field__input {
	width: 100%;
	padding: 0px 24px !important;
	height: 56px !important;
	box-shadow: none !important;
	background: #fbfbfb;
	border: 1px solid #b1b1b1;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 16px;
	line-height: 100%;
	color: #626262;
	box-shadow: none !important;
	outline: none !important;
}
.field__input:focus {
	border: 2px solid #b1b1b1;
}
.field__label {
	position: absolute;
	top: 18.5px;
	left: 24px;
	color: #626262;
	font-weight: 400;
	transform: translate3d(0, 0, 0);
	transition: all 0.2s ease-in-out;
}
.field__label--active {
	transform: translateY(-160%);
	font-size: 0.75em;
	background-color: white;
	color: #626262;
	padding: 0 4px;
}
.field__button {
	width: 100%;
	display: block;
	background: white;
	border-radius: 8px;
	outline: none;
	border: 2px solid #f36565;
	padding: 22px 32px;
	text-decoration: none;
	text-align: center;
	box-sizing: border-box;
	color: #f36565;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}
.field__button:hover {
	background-color: #f36565;
	color: white;
}

.field__error {
	color: #f36565;
}

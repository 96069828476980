.slider-wrapper {
	/*border-top: 2px solid #454545;
	border-bottom: 2px solid #454545;*/
}
.splide__track {
	overflow: visible !important;
}
.splide__slide {
	border: none !important;
}
